<template>
  <div
    v-if="cs_mode"
    :class="{
      'cs-banner t-center d-flex': true,
    }"
  >
    <span class="notification-message m-auto t-black t-bold"> CUSTOMER SUPPORT LOGIN MODE FOR USER  *** {{ email }} *** </span>
  </div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'CsBanner',
		props: {
			dismissible: {
				type: Boolean,
				default: true,
			},
		},
		computed: {
			...mapGetters('user', ['email','cs_mode']),
		},
		mounted() {
			window.onbeforeunload = this.turnOff
		},
		methods: {
		},
	}
</script>
<style lang="scss">
.cs-banner{
  background-color: #a6d5d5;
  height: 50px;
  border: 2px solid black;
  z-index: 3;
  position: fixed;
  width: 100%;
}
</style>

